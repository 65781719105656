<template>
  <div class="profile-container">
    <!-- v-if="!$vuetify.breakpoint.smAndDown" -->
    <Breadcrumb :items="breadCrumbs" />
    <v-tabs
      v-if="showTcoMenu && isOroUser"
      centered
      class="profile-navigation-menu"
    >
      <v-tabs-slider />
      <v-tab :to="{ name: 'Receipts' }" value="Receipts"
        ><span
          ><v-icon>$receipt</v-icon>{{ $t("dashboard.menu.receipts") }}</span
        ></v-tab
      >
      <v-tab :to="{ name: 'ProfileSummary' }" value="ProfileSummary"
        ><span
          ><v-icon>$profile</v-icon>{{ $t("dashboard.menu.summary") }}</span
        ></v-tab
      >
      <v-tab :to="{ name: 'TcoRules' }" value="TcoRules"
        ><span
          ><v-icon>$gift</v-icon>{{ $t("dashboard.menu.rules") }}</span
        ></v-tab
      >
    </v-tabs>
    <v-container class="profile-section">
      <v-row>
        <v-col
          cols="12"
          md="3"
          xl="2"
          class="d-none d-md-inline pb-0 d-print-none"
          ><UserMenu :user="getUser" class="grey lighten-1 user-menu"
        /></v-col>
        <v-col cols="12" md="9" xl="10"> <router-view></router-view></v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="scss">
.profile-container {
  .profile-navigation-menu.v-tabs {
    .v-tabs-slider {
      background-color: orange !important;
    }
    .v-tab {
      max-width: 25%;
      padding: 0px 20px;
      letter-spacing: 0.1px !important;
      font-weight: bold;
      border-bottom: 2px solid var(--v-primary-base);
      @media #{map-get($display-breakpoints, 'xs-only')} {
        padding: 0px 10px;
      }

      span {
        font-size: 12px;
        text-transform: none;
        color: var(--v-primary-base);
        .v-icon {
          color: inherit;
          margin-right: 5px;
        }
      }
      &.v-tab--active {
        span {
          color: orange !important;
        }
      }
    }
  }
  .user-menu {
    position: sticky;
    top: 160px;
  }
}
</style>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import UserMenu from "@/components/navigation/UserMenu.vue";

import get from "lodash/get";
import { mapGetters } from "vuex";

export default {
  name: "Profile",
  components: { Breadcrumb, UserMenu },
  computed: {
    ...mapGetters({
      getLegalById: "cart/getLegalById",
      getUser: "cart/getUser"
    }),
    isOroUser() {
      return this.getLegalById(12);
    },
    showTcoMenu() {
      let result = get(this, "$route.meta.showTcoMenu", false);
      return result;
    },
    breadCrumbs() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      const breadCrumbs = [{ href: "/", text: "Homepage" }];
      // needed to handle the intermediary entries for nested vue routes
      let breadcrumb = "";
      // let lastIndexFound = 0;

      for (let i = 1; i < pathArray.length; ++i) {
        // from 1 to skip "Area personale"
        breadcrumb = `${breadcrumb}${"/profile/"}${pathArray[i]}`;
        if (
          this.$route.matched[i] &&
          Object.hasOwnProperty.call(this.$route.matched[i], "meta") &&
          Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadCrumb")
        ) {
          breadCrumbs.push({
            href: breadcrumb,
            // i !== 0 && pathArray[i - (i - lastIndexFound)]
            //   ? "/" + pathArray[i - (i - lastIndexFound)] + breadcrumb
            //   : breadcrumb,
            exact: false,
            disabled: i + 1 === pathArray.length,
            text: this.$route.matched[i].meta.breadCrumb || pathArray[i]
          });
          // lastIndexFound = i;
          // breadcrumb = "";
        }
      }
      return breadCrumbs;
    }
  }
};
</script>
